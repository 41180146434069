import { setDefaults, fromAddress } from "react-geocode";

setDefaults({
    key: "AIzaSyARARtO1np6AdfWNQwJOE_tHnx__8ecCmg", // Your API key here.
    language: "en", // Default language for responses.
    region: "nl", // Default region for responses.
  });


  // Get latitude & longitude from address.

  const getCoordinatesFromAddress = (address) => {
    return new Promise((resolve, reject) => {
      fromAddress(address).then(response => {
        const { lat, lng } = response.results[0].geometry.location;
        resolve({ lat, lng });
      }).catch(error => {
        console.error(error);
        reject(error);
      });
    });
  };


export { getCoordinatesFromAddress }