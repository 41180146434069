import React, { useEffect, useState, useImperativeHandle, forwardRef } from 'react';
import { motion, useMotionValue, useAnimation, useTransform } from "framer-motion";

import './index.css';

import MapComponent from '../GoogleMaps';
import { getCoordinatesFromAddress } from '../Geocode';
import { labeler } from '../Labeler';

export const SwipeableMaps = forwardRef(({ data, setData, setCurrentIndex, currentIndex, setIsPrevious, isPrevious }, ref) => {

    const [totalIndex, setTotalIndex] = useState(0)

    const [currentData, setCurrentData] = useState(null)
    const [startCoords, setStartCoords] = useState(null)
    const [endCoords, setEndCoords] = useState(null)
    const [labelPoints, setLabelPoints] = useState(3)

    const [hasCoords, setHasCoords] = useState(false)


    // new code for preloading next card
    const [nextData, setNextData] = useState(null); // Holds the data for the next card
    const [nextStartCoords, setNextStartCoords] = useState(null);
    const [nextEndCoords, setNextEndCoords] = useState(null);
    const [nextLabelPoints, setNextLabelPoints] = useState(3);

    const [prevData, setPrevData] = useState(null); // Holds the data for the previous card
    const [prevStartCoords, setPrevStartCoords] = useState(null);
    const [prevEndCoords, setPrevEndCoords] = useState(null);
    const [prevLabelPoints, setPrevLabelPoints] = useState(3);



    const controls = useAnimation();
  const x = useMotionValue(0);
  const xInput = [-90, 0, 90];
  const background = useTransform(x, xInput, [
    "linear-gradient(180deg, #ff008c 0%, rgb(211, 9, 225) 100%)",
    "linear-gradient(180deg, #7700ff 0%, rgb(68, 0, 255) 100%)",
    "linear-gradient(180deg, rgb(230, 255, 0) 0%, rgb(3, 209, 0) 100%)",
  ]);
  const color = useTransform(x, xInput, [
    "rgb(211, 9, 225)",
    "rgb(68, 0, 255)",
    "rgb(3, 209, 0)",
  ]);
  const tickPath = useTransform(x, [10, 80], [0, 1]);
  const crossPathA = useTransform(x, [-10, -55], [0, 1]);
  const crossPathB = useTransform(x, [-50, -100], [0, 1]);

  const dragElastic = 0.6;


  const handleData = (index) => {
    const mapData = data[index]; // Current map data

    setCurrentData(mapData)
        
    const startAddress = mapData.start_point
    const endAddress = mapData.end_point
  
    getCoordinatesFromAddress(startAddress).then(startCoords => {
      setStartCoords(startCoords);
      getCoordinatesFromAddress(endAddress).then(endCoords => {
        setEndCoords(endCoords);
        return endCoords
      }).then(endCoords => {
        labeler(mapData, startCoords, endCoords).then(out => {
          setLabelPoints(out)
        })
      });
    });
  }

  const preloadNextData = (index) => {
    if (index < data.length - 1) { // Check if there is a next card to load
      const nextIndex = index + 1;
      const mapData = data[nextIndex];

      const startAddress = mapData.start_point
      const endAddress = mapData.end_point
      

      getCoordinatesFromAddress(startAddress).then(startCoords => {
        setNextStartCoords(startCoords);
        getCoordinatesFromAddress(endAddress).then(endCoords => {
          setNextEndCoords(endCoords);
          return endCoords
        }).then(endCoords => {
          labeler(mapData, startCoords, endCoords).then(out => {
            setNextLabelPoints(out);
            setNextData(mapData); // Preload next card data
          })
        });
      });
    }
  };

  
  useEffect(() => {
    if (startCoords && endCoords && !hasCoords) {
    setHasCoords(true); // Set to true only when both coordinates are fetched
    }
  }, [startCoords, endCoords])

  
  useEffect(() => {
    if (data.length !== 0 && currentIndex === 0) {
      setTotalIndex(data.length-1)
      handleData(0)
      preloadNextData(0)
    }
  }, [data])

  useEffect(() => {
    // This effect ensures that whenever currentIndex updates, we check if we have preloaded data
    // If we do, we set it as the current to display instantly
    if (nextData && currentIndex > 0) { // Avoids setting nextData on initial render
    // setCurrentData(nextData);
      // setStartCoords(nextStartCoords);
      // setEndCoords(nextEndCoords);
      // setLabelPoints(nextLabelPoints);
  
      // Preload next card's data if exists
      preloadNextData(currentIndex);
    } 
  }, [currentIndex]); // Rerun when currentIndex updates


  const handleUndo = () => {
    if (!isPrevious) {
      setIsPrevious(true);
      setCurrentIndex(currentIndex - 1);
      setCurrentData(prevData);
      setStartCoords(prevStartCoords);
      setEndCoords(prevEndCoords);
      setLabelPoints(prevLabelPoints);
  
      // Call the wiggle animation function
      wiggleAnimation(controls);
    }
  };
  

  useImperativeHandle(ref, () => ({
    handleUndo
  }));


  const handleDragEnd = () => {
    const latestPoint = x.get();
  
    // Define spring options for a more natural animation feel
    const springOptions = {
      type: "spring",
      stiffness: 300, // Adjust the stiffness for a snappier or softer spring
      damping: 25, // Adjust damping for less or more oscillation
      mass: 1, // Mass affects the duration of the animation
    };
  
    // Logic to handle swipe direction and update data accordingly
    if (latestPoint < -90) {
      setData(prevData => {
        const newData = [...prevData];
        newData[currentIndex].category = "prive";
        return newData;
      });
      animateSwipeCompletion(springOptions, "left", latestPoint);
    } else if (latestPoint > 90) {
      setData(prevData => {
        const newData = [...prevData];
        newData[currentIndex].category = "business";
        return newData;
      });
      animateSwipeCompletion(springOptions, "right", latestPoint);
    }
  
    // Handles the case for swiping to the next data point
    if (currentIndex !== totalIndex && (latestPoint < -90 || latestPoint > 90)) {
      if (currentIndex < data.length - 1) {
      // handleData(currentIndex + 1);
      // preloadNextData(currentIndex + 1);
      setCurrentIndex(currentIndex + 1);
      setIsPrevious(false)

      setCurrentData(nextData);
      setStartCoords(nextStartCoords);
      setEndCoords(nextEndCoords);
      setLabelPoints(nextLabelPoints);

      // keep history of previous data card.
      setPrevData(currentData);
      setPrevStartCoords(startCoords);
      setPrevEndCoords(endCoords);
      setPrevLabelPoints(labelPoints);


      }

    } else {
      // Animate back to the center if not swiping to the next data point or changing category
      controls.start({ x: 0, transition: springOptions });
    }
  };
  
  function animateSwipeCompletion(springOptions, direction, latestPoint) {
    // Apply a swift movement in the direction of the swipe
    // then quickly reset to the center position for the next card.
    const swipeDistance = latestPoint; // Distance to indicate swipe direction more clearly
    controls.start({
      scale: 1.1,
      x: direction === "left" ? swipeDistance : swipeDistance, // Move quickly in the direction of the swipe
      transition: { ...springOptions, duration: 0 }, // Short duration for a snappier feel
    }).then(() => {
      // Immediately reset the card to its original state without noticeable delay
      controls.start({
        scale: 1,
        x: 0, // Reset position to center
        transition: { ...springOptions, duration: 0 }, // Instant reset for readiness of the next swipe
      });
    });
  }

  async function wiggleAnimation(controls) {
    const sequence = [
      { x: -10, transition: { duration: 0.05 } },
      { x: 10, transition: { duration: 0.05 } },
      { x: -10, transition: { duration: 0.05 } },
      { x: 10, transition: { duration: 0.05 } },
      { x: 0, transition: { duration: 0.05 } } // Return to center
    ];
    
    for (const anim of sequence) {
      await controls.start(anim);
    }
  }
  
  


  return (
    <div className="outer-swipe-container">
    <motion.div className="example-container" style={{ background }}>
      <motion.div
        className="box"
        style={{ x }}
        // onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
        drag="x"
        dragElastic={dragElastic}
        dragConstraints={{ left: 0, right: 0 }}
        animate={controls}
      >
      
      {hasCoords ? <MapComponent currentData={currentData} startCoords={startCoords} endCoords={endCoords} labelPoints={labelPoints}/> : null }

        <svg className="progress-icon" viewBox="0 0 50 50">
          <motion.path
            fill="none"
            strokeWidth="2"
            stroke={color}
            d="M 0, 20 a 20, 20 0 1,0 40,0 a 20, 20 0 1,0 -40,0"
            style={{ translateX: 5, translateY: 5 }}
          />
          <motion.path
            fill="none"
            strokeWidth="2"
            stroke={color}
            d="M14,26 L 22,33 L 35,16"
            strokeDasharray="0 1"
            style={{ pathLength: tickPath }}
          />
          <motion.path
            fill="none"
            strokeWidth="2"
            stroke={color}
            d="M17,17 L33,33"
            strokeDasharray="0 1"
            style={{ pathLength: crossPathA }}
          />
          <motion.path
            fill="none"
            strokeWidth="2"
            stroke={color}
            d="M33,17 L17,33"
            strokeDasharray="0 1"
            style={{ pathLength: crossPathB }}
          />
        </svg>
      </motion.div>
    </motion.div>
    </div>
  );
});

export default SwipeableMaps;
