// const fetch = require("node-fetch"); // If you're using Node.js

const pinguin = "6cf444f6a0d44e9bb7a1ced414e98142";

const clientAddresses = [
    {
        "id": 1,
        "name": "Prof-IT4All BV",
        "visit_street": " Noordenweg ",
        "visit_street_nr": "75",
        "visit_zip_code": "2984 AG",
        "visit_city": "Ridderkerk",
        "invoice_street": " Postbus ",
        "invoice_street_nr": "9326",
        "invoice_zip_code": "3007 AH",
        "invoice_city": "Rotterdam",
        "long": 4.60278,
        "lat": 51.8725
    },
    {
        "id": 2,
        "name": "Stichting Casade",
        "visit_street": "Min. Goselinglaan",
        "visit_street_nr": "8",
        "visit_zip_code": "5103 BJ",
        "visit_city": "Dongen",
        "invoice_street": "Postbus",
        "invoice_street_nr": "134",
        "invoice_zip_code": "5100 AC",
        "invoice_city": "Dongen",
        "long": 4.9476376,
        "lat": 51.6265801
    },
    {
        "id": 3,
        "name": "Kooijman BV",
        "visit_street": " invullen",
        "visit_street_nr": "0",
        "visit_zip_code": null,
        "visit_city": "Ridderkerk",
        "invoice_street": "",
        "invoice_street_nr": null,
        "invoice_zip_code": null,
        "invoice_city": null,
        "long": 4.60278,
        "lat": 51.8725
    },
    {
        "id": 4,
        "name": "Zayaz",
        "visit_street": " Eekbrouwersweg ",
        "visit_street_nr": "8",
        "visit_zip_code": "5233 VG",
        "visit_city": "Den Bosch",
        "invoice_street": " Postbus ",
        "invoice_street_nr": "488",
        "invoice_zip_code": "5201 AL",
        "invoice_city": "Den Bosch",
        "long": 5.30417,
        "lat": 51.69917
    },
    {
        "id": 5,
        "name": "CorpoMatch BV",
        "visit_street": " Noordenweg ",
        "visit_street_nr": "75",
        "visit_zip_code": "2984 AG",
        "visit_city": "Ridderkerk",
        "invoice_street": " Noordenweg ",
        "invoice_street_nr": "75",
        "invoice_zip_code": "2984 AG",
        "invoice_city": "Ridderkerk",
        "long": 4.60278,
        "lat": 51.8725
    },
    {
        "id": 6,
        "name": "Asito",
        "visit_street": " invullen",
        "visit_street_nr": "0",
        "visit_zip_code": "",
        "visit_city": "Hengelo",
        "invoice_street": "",
        "invoice_street_nr": null,
        "invoice_zip_code": "",
        "invoice_city": "",
        "long": 6.79306,
        "lat": 52.26583
    },
    {
        "id": 7,
        "name": "Portaal",
        "visit_street": " invullen",
        "visit_street_nr": "0",
        "visit_zip_code": null,
        "visit_city": "Veenendaal",
        "invoice_street": "",
        "invoice_street_nr": null,
        "invoice_zip_code": null,
        "invoice_city": null,
        "long": 5.55891,
        "lat": 52.02863
    },
    {
        "id": 8,
        "name": "Woonzorg",
        "visit_street": " Prof. E.M.Meijerslaan ",
        "visit_street_nr": "3",
        "visit_zip_code": "1183 AV",
        "visit_city": "Amstelveen",
        "invoice_street": " Postbus ",
        "invoice_street_nr": "339",
        "invoice_zip_code": "1180 AH",
        "invoice_city": "Amstelveen",
        "long": 4.8762994,
        "lat": 52.3175572
    },
    {
        "id": 9,
        "name": "Vidomes",
        "visit_street": " Kleveringweg ",
        "visit_street_nr": "24",
        "visit_zip_code": "2616 LZ",
        "visit_city": "Delft",
        "invoice_street": " Kleveringweg ",
        "invoice_street_nr": "24",
        "invoice_zip_code": "2616 LZ",
        "invoice_city": "Delft",
        "long": 4.3643405,
        "lat": 52.024559
    },
    {
        "id": 10,
        "name": "Mitros",
        "visit_street": " Koningin Wilhelminalaan ",
        "visit_street_nr": "9",
        "visit_zip_code": "3527 LA",
        "visit_city": "Utrecht",
        "invoice_street": " Postbus ",
        "invoice_street_nr": "8217",
        "invoice_zip_code": "3503 RE",
        "invoice_city": "Utrecht",
        "long": 5.1055468,
        "lat": 52.0769193
    },
    {
        "id": 11,
        "name": "Eigen Haard",
        "visit_street": " Arlandaweg ",
        "visit_street_nr": "88",
        "visit_zip_code": "1043 EX",
        "visit_city": "Amsterdam",
        "invoice_street": " Postbus ",
        "invoice_street_nr": "67065",
        "invoice_zip_code": "1060 JB",
        "invoice_city": "Amsterdam",
        "long": 4.8372336,
        "lat": 52.3856715
    },
    {
        "id": 12,
        "name": "Westhoek Wonen",
        "visit_street": "  Rendementsweg ",
        "visit_street_nr": "14",
        "visit_zip_code": "3641 SL",
        "visit_city": "Mijdrecht",
        "invoice_street": "",
        "invoice_street_nr": null,
        "invoice_zip_code": "",
        "invoice_city": "",
        "long": 4.8625,
        "lat": 52.20667
    },
    {
        "id": 13,
        "name": "Woonbron",
        "visit_street": " Eeze ",
        "visit_street_nr": "11",
        "visit_zip_code": "3191 ER",
        "visit_city": "Rotterdam",
        "invoice_street": " Postbus ",
        "invoice_street_nr": "2346",
        "invoice_zip_code": "3000 CH",
        "invoice_city": "ROTTERDAM",
        "long": 4.3558573,
        "lat": 51.8661489
    },
    {
        "id": 14,
        "name": "Woonkracht 10",
        "visit_street": " Burgemeester de Bru\u00c3\u00afnelaan ",
        "visit_street_nr": "97",
        "visit_zip_code": "3331 AC",
        "visit_city": "Zwijndrecht",
        "invoice_street": " Postbus ",
        "invoice_street_nr": "246",
        "invoice_zip_code": "3330 AE",
        "invoice_city": "Zwijndrecht",
        "long": 4.63333,
        "lat": 51.8175
    },
    {
        "id": 15,
        "name": "HW Wonen",
        "visit_street": " Langestraat ",
        "visit_street_nr": "33",
        "visit_zip_code": "3295 BJ",
        "visit_city": "s-Gravendeel",
        "invoice_street": " Postbus ",
        "invoice_street_nr": "1502",
        "invoice_zip_code": "3260 BA",
        "invoice_city": "Oud-Beijerland",
        "long": 4.6187077,
        "lat": 51.7775784
    },
    {
        "id": 16,
        "name": "Woongoed Flakkee",
        "visit_street": " Landbouwweg ",
        "visit_street_nr": "1",
        "visit_zip_code": "1211 CN",
        "visit_city": "Middelharnis",
        "invoice_street": "",
        "invoice_street_nr": null,
        "invoice_zip_code": "",
        "invoice_city": "",
        "long": 4.1718055,
        "lat": 51.7514906
    },
    {
        "id": 17,
        "name": "Arcade Wonen",
        "visit_street": " Stokdijkkade ",
        "visit_street_nr": "26",
        "visit_zip_code": "2671 GX",
        "visit_city": "Naaldwijk",
        "invoice_street": "",
        "invoice_street_nr": null,
        "invoice_zip_code": "",
        "invoice_city": "",
        "long": 4.212245,
        "lat": 51.9954511
    },
    {
        "id": 19,
        "name": "Zeeuwland",
        "visit_street": " Kerkhof ",
        "visit_street_nr": "1",
        "visit_zip_code": "4301 BZ",
        "visit_city": "Zierikzee",
        "invoice_street": "",
        "invoice_street_nr": null,
        "invoice_zip_code": "",
        "invoice_city": "",
        "long": 3.915598,
        "lat": 51.6496522
    },
    {
        "id": 20,
        "name": "DSA-Vision Solutions",
        "visit_street": " invullen",
        "visit_street_nr": "0",
        "visit_zip_code": "",
        "visit_city": "Hoofddorp",
        "invoice_street": "",
        "invoice_street_nr": null,
        "invoice_zip_code": "",
        "invoice_city": "",
        "long": 4.68889,
        "lat": 52.3025
    },
    {
        "id": 21,
        "name": "Dudok Wonen",
        "visit_street": " Larenseweg ",
        "visit_street_nr": "32",
        "visit_zip_code": "1221 CN",
        "visit_city": "Hilversum",
        "invoice_street": "",
        "invoice_street_nr": null,
        "invoice_zip_code": "",
        "invoice_city": "",
        "long": 5.1867001,
        "lat": 52.229614
    },
    {
        "id": 22,
        "name": "Ensemble BV",
        "visit_street": " Europaweg ",
        "visit_street_nr": "143",
        "visit_zip_code": "2711 EP",
        "visit_city": "Zoetermeer",
        "invoice_street": " Postbus ",
        "invoice_street_nr": "419",
        "invoice_zip_code": "2700 AK",
        "invoice_city": "Zoetermeer",
        "long": 4.49306,
        "lat": 52.0575
    },
    {
        "id": 25,
        "name": "Vestia Groep",
        "visit_street": " Kruisplein ",
        "visit_street_nr": "15",
        "visit_zip_code": "3014 DB",
        "visit_city": "Rotterdam",
        "invoice_street": " Postbus ",
        "invoice_street_nr": "29013",
        "invoice_zip_code": "3001 GA",
        "invoice_city": "Rotterdam",
        "long": 4.4690379,
        "lat": 51.9216022
    },
    {
        "id": 26,
        "name": "Woonstichting Leystromen",
        "visit_street": " Julianastraat ",
        "visit_street_nr": "51",
        "visit_zip_code": "5121 LN",
        "visit_city": "Rijen",
        "invoice_street": " Postbus ",
        "invoice_street_nr": "70",
        "invoice_zip_code": "5120 AB",
        "invoice_city": "Rijen",
        "long": 4.9242033,
        "lat": 51.5809336
    },
    {
        "id": 28,
        "name": "Cegeka DSA",
        "visit_street": " Bastion ",
        "visit_street_nr": "4",
        "visit_zip_code": "3905 NJ",
        "visit_city": "Veenendaal",
        "invoice_street": " Bastion ",
        "invoice_street_nr": "4",
        "invoice_zip_code": "3905 NJ",
        "invoice_city": "Veenendaal",
        "long": 5.5647806,
        "lat": 52.0428595
    },
    {
        "id": 29,
        "name": "ZVH",
        "visit_street": " Peperstraat ",
        "visit_street_nr": "86",
        "visit_zip_code": "1502 AK",
        "visit_city": "Zaandam",
        "invoice_street": " Peperstraat ",
        "invoice_street_nr": "86",
        "invoice_zip_code": "1502 AK",
        "invoice_city": "Zaandam",
        "long": 4.827534,
        "lat": 52.4410416
    },
    {
        "id": 30,
        "name": "Stichting Havensteder",
        "visit_street": " Scheepmakerspassage / The Red Apple",
        "visit_street_nr": "1",
        "visit_zip_code": "3000 BP",
        "visit_city": "Rotterdam",
        "invoice_street": " Postbus ",
        "invoice_street_nr": "1612",
        "invoice_zip_code": "3000 BP",
        "invoice_city": "Rotterdam",
        "long": 4.47917,
        "lat": 51.9225
    },
    {
        "id": 31,
        "name": "Servatius Wonen & Vastgoed",
        "visit_street": " Wim Duisenbergplantsoen ",
        "visit_street_nr": "41",
        "visit_zip_code": "6221 SE",
        "visit_city": "Maastricht",
        "invoice_street": " Wim Duisenburgplantsoen ",
        "invoice_street_nr": "41",
        "invoice_zip_code": "6221 SE",
        "invoice_city": "Maastricht",
        "long": 5.704363,
        "lat": 50.8411897
    },
    {
        "id": 32,
        "name": "Area Wonen",
        "visit_street": " Leeuweriksweg ",
        "visit_street_nr": "12",
        "visit_zip_code": "5402 XD",
        "visit_city": "Uden",
        "invoice_street": " Postbus ",
        "invoice_street_nr": "548",
        "invoice_zip_code": "5400 AM",
        "invoice_city": "Uden",
        "long": 5.6185196,
        "lat": 51.661297
    },
    {
        "id": 33,
        "name": "Wonen Zuid",
        "visit_street": " Dr. Poelsstraat ",
        "visit_street_nr": "8",
        "visit_zip_code": "6411 HH",
        "visit_city": "Heerlen",
        "invoice_street": " Postbus ",
        "invoice_street_nr": "400",
        "invoice_zip_code": "6040 AK",
        "invoice_city": "Roermond",
        "long": 5.9787305,
        "lat": 50.8863028
    },
    {
        "id": 34,
        "name": "BO-EX",
        "visit_street": " Jan Cornelisz Maylaan ",
        "visit_street_nr": "18",
        "visit_zip_code": "3526 GV",
        "visit_city": "Utrecht",
        "invoice_street": " Postbus ",
        "invoice_street_nr": "3151",
        "invoice_zip_code": "3502 GD",
        "invoice_city": "Utrecht",
        "long": 5.0941145,
        "lat": 52.0702756
    },
    {
        "id": 35,
        "name": "SAASNOW",
        "visit_street": "",
        "visit_street_nr": null,
        "visit_zip_code": "",
        "visit_city": "Hellevoetsluis",
        "invoice_street": "",
        "invoice_street_nr": null,
        "invoice_zip_code": "",
        "invoice_city": "Hellevoetsluis",
        "long": 4.13333,
        "lat": 51.83333
    },
    {
        "id": 37,
        "name": "WonenBreburg",
        "visit_street": " Prof. de Moorplein ",
        "visit_street_nr": "521",
        "visit_zip_code": "5037 DR",
        "visit_city": "Tilburg",
        "invoice_street": " Postbus ",
        "invoice_street_nr": "409",
        "invoice_zip_code": "5000 AK",
        "invoice_city": "Tilburg",
        "long": 5.0500512,
        "lat": 51.5619483
    },
    {
        "id": 38,
        "name": "SAS",
        "visit_street": "",
        "visit_street_nr": null,
        "visit_zip_code": "",
        "visit_city": "Huizen",
        "invoice_street": "",
        "invoice_street_nr": null,
        "invoice_zip_code": "",
        "invoice_city": "Huizen",
        "long": 5.24167,
        "lat": 52.29917
    },
    {
        "id": 39,
        "name": "Trivire",
        "visit_street": " Callistolaan ",
        "visit_street_nr": "2",
        "visit_zip_code": "3318 JA",
        "visit_city": "Dordrecht",
        "invoice_street": " Postbus ",
        "invoice_street_nr": "8008",
        "invoice_zip_code": "3301 CA",
        "invoice_city": "Dordrecht",
        "long": 4.6715893,
        "lat": 51.7848245
    },
    {
        "id": 40,
        "name": "GroenWest",
        "visit_street": " Oslolaan ",
        "visit_street_nr": "2",
        "visit_zip_code": "3446 AA",
        "visit_city": "Woerden",
        "invoice_street": " Postbus ",
        "invoice_street_nr": "2171",
        "invoice_zip_code": "3440 DD",
        "invoice_city": "Woerden",
        "long": 4.8977469,
        "lat": 52.084247
    },
    {
        "id": 41,
        "name": "TRUDO",
        "visit_street": " Torenallee ",
        "visit_street_nr": "34",
        "visit_zip_code": "5617 BD",
        "visit_city": "Eindhoven",
        "invoice_street": " Postbus ",
        "invoice_street_nr": "36",
        "invoice_zip_code": "5600 AJ",
        "invoice_city": "Eindhoven",
        "long": 5.4578756,
        "lat": 51.4467077
    },
    {
        "id": 42,
        "name": "Volkshuisvesting Arnhem",
        "visit_street": " Kadestraat ",
        "visit_street_nr": "1",
        "visit_zip_code": "6811 CA",
        "visit_city": "Arnhem",
        "invoice_street": " Postbus ",
        "invoice_street_nr": "5229",
        "invoice_zip_code": "6802 EE",
        "invoice_city": "Arnhem",
        "long": 5.9119078,
        "lat": 51.9761491
    },
    {
        "id": 43,
        "name": "Stadgenoot",
        "visit_street": " Sarphatistraat ",
        "visit_street_nr": "370",
        "visit_zip_code": "1018 GW",
        "visit_city": "Amsterdam",
        "invoice_street": " Postbus ",
        "invoice_street_nr": "700",
        "invoice_zip_code": "1000 AS",
        "invoice_city": "Amsterdam",
        "long": 4.9154771,
        "lat": 52.3625331
    },
    {
        "id": 44,
        "name": "Omnia Wonen",
        "visit_street": " Scheepssingel ",
        "visit_street_nr": "12",
        "visit_zip_code": "3841 KS",
        "visit_city": "Harderwijk",
        "invoice_street": " Postbus ",
        "invoice_street_nr": "418",
        "invoice_zip_code": "3840 AK",
        "invoice_city": "Harderwijk",
        "long": 5.6242527,
        "lat": 52.3504854
    },
    {
        "id": 45,
        "name": "NS",
        "visit_street": " Laan van Puntenburg k",
        "visit_street_nr": "293",
        "visit_zip_code": "3500 HA",
        "visit_city": "Utrecht",
        "invoice_street": " Amersfoortseweg",
        "invoice_street_nr": "14-A",
        "invoice_zip_code": "3751 LK",
        "invoice_city": "Bunschoten",
        "long": 5.12222,
        "lat": 52.09083
    },
    {
        "id": 47,
        "name": "ITON Professionals B.V.",
        "visit_street": " Het Eeftink ",
        "visit_street_nr": "22",
        "visit_zip_code": "7541 WH",
        "visit_city": "Enschede",
        "invoice_street": " Het Eeftink ",
        "invoice_street_nr": "22",
        "invoice_zip_code": "7541 WH",
        "invoice_city": "Enschede",
        "long": 6.8905165,
        "lat": 52.2072554
    },
    {
        "id": 48,
        "name": "Hoogheemraadschap  Schieland en de Krimpenerwaard",
        "visit_street": " Maasboulevard ",
        "visit_street_nr": "123",
        "visit_zip_code": "3063 GK",
        "visit_city": "Rotterdam",
        "invoice_street": " Postbus ",
        "invoice_street_nr": "4059",
        "invoice_zip_code": "3006 AB",
        "invoice_city": "Rotterdam",
        "long": 4.5047256,
        "lat": 51.9198856
    },
    {
        "id": 49,
        "name": "De Alliantie",
        "visit_street": " Bestevaer ",
        "visit_street_nr": "48",
        "visit_zip_code": "1271 ZA",
        "visit_city": "Huizen",
        "invoice_street": " Postbus ",
        "invoice_street_nr": "105",
        "invoice_zip_code": "1200 AC",
        "invoice_city": "Hilversum",
        "long": 5.24167,
        "lat": 52.29917
    },
    {
        "id": 50,
        "name": "Laurentius Wonen",
        "visit_street": " Loevesteinstraat ",
        "visit_street_nr": "20",
        "visit_zip_code": "4834 ED",
        "visit_city": "Breda",
        "invoice_street": " Postbus ",
        "invoice_street_nr": "2199",
        "invoice_zip_code": "4800 CD",
        "invoice_city": "Breda",
        "long": 4.8061813,
        "lat": 51.5776005
    },
    {
        "id": 51,
        "name": "TBV Wonen",
        "visit_street": " Spoorlaan ",
        "visit_street_nr": "430",
        "visit_zip_code": "5038 CG",
        "visit_city": "TILBURG",
        "invoice_street": " Spoorlaan ",
        "invoice_street_nr": "430",
        "invoice_zip_code": "5038 CG",
        "invoice_city": "TILBURG",
        "long": 5.0820952,
        "lat": 51.5601167
    },
    {
        "id": 52,
        "name": "SSH",
        "visit_street": " Plompetorengracht ",
        "visit_street_nr": "9",
        "visit_zip_code": "3512 CA",
        "visit_city": "Utrecht",
        "invoice_street": " Postbus ",
        "invoice_street_nr": "85042",
        "invoice_zip_code": "3508 AA",
        "invoice_city": "Utrecht",
        "long": 5.1237883,
        "lat": 52.0959235
    },
    {
        "id": 53,
        "name": "De Woonplaats",
        "visit_street": " Wethouder Beverstraat ",
        "visit_street_nr": "175",
        "visit_zip_code": "7543 BK",
        "visit_city": "Enschede",
        "invoice_street": " Postbus ",
        "invoice_street_nr": "23",
        "invoice_zip_code": "7500 AA",
        "invoice_city": "Enschede",
        "long": 6.89583,
        "lat": 52.21833
    },
    {
        "id": 54,
        "name": "Woonstichting Vooruitgang",
        "visit_street": " Hoofdstraat ",
        "visit_street_nr": "10",
        "visit_zip_code": "2171 AT",
        "visit_city": "SASSENHEIM",
        "invoice_street": " Hoofdstraat ",
        "invoice_street_nr": "10",
        "invoice_zip_code": "2171 AT",
        "invoice_city": "SASSENHEIM",
        "long": 4.5036262,
        "lat": 52.2154615
    },
    {
        "id": 55,
        "name": "VanAlckmaer Wonen",
        "visit_street": " Keetgracht ",
        "visit_street_nr": "1",
        "visit_zip_code": "1811 AD",
        "visit_city": "Alkmaar",
        "invoice_street": " Keetgracht ",
        "invoice_street_nr": "1",
        "invoice_zip_code": "1811 AD",
        "invoice_city": "Alkmaar",
        "long": 4.74861,
        "lat": 52.63167
    },
    {
        "id": 56,
        "name": "Woonwenz",
        "visit_street": " Molenbossen ",
        "visit_street_nr": "618",
        "visit_zip_code": "5923 AJ",
        "visit_city": "VENLO",
        "invoice_street": " Molenbossen ",
        "invoice_street_nr": "618",
        "invoice_zip_code": "5923 AJ",
        "invoice_city": "VENLO",
        "long": 6.1438255,
        "lat": 51.360642
    },
    {
        "id": 57,
        "name": "Reggewoon",
        "visit_street": " Storkstraat ",
        "visit_street_nr": "11",
        "visit_zip_code": "7442 LW",
        "visit_city": "Nijverdal",
        "invoice_street": " Postbus ",
        "invoice_street_nr": "325",
        "invoice_zip_code": "7440 AH",
        "invoice_city": "NIJVERDAL",
        "long": 6.4729311,
        "lat": 52.3641466
    },
    {
        "id": 58,
        "name": "Elan Wonen",
        "visit_street": " Houtplein ",
        "visit_street_nr": "33",
        "visit_zip_code": "2003 BR",
        "visit_city": "Haarlem",
        "invoice_street": " Postbus ",
        "invoice_street_nr": "1646",
        "invoice_zip_code": "2003 BR",
        "invoice_city": "Haarlem",
        "long": 4.63683,
        "lat": 52.38084
    },
    {
        "id": 59,
        "name": "Woningstichting GoedeStede",
        "visit_street": " Verzetslaan ",
        "visit_street_nr": "141",
        "visit_zip_code": "1318 BT",
        "visit_city": "ALMERE",
        "invoice_street": " Postbus ",
        "invoice_street_nr": "10088",
        "invoice_zip_code": "1301 AB",
        "invoice_city": "ALMERE",
        "long": 5.21413,
        "lat": 52.37025
    },
    {
        "id": 60,
        "name": "Woonstichting Thuis",
        "visit_street": " Kronehoefstraat ",
        "visit_street_nr": "83",
        "visit_zip_code": "5612 HL",
        "visit_city": "EINDHOVEN",
        "invoice_street": " Kronehoefstraat ",
        "invoice_street_nr": "83",
        "invoice_zip_code": "5612 HL",
        "invoice_city": "EINDHOVEN",
        "long": 5.4667837,
        "lat": 51.4519742
    },
    {
        "id": 61,
        "name": "Woonvizier",
        "visit_street": " Middelmeede ",
        "visit_street_nr": "40",
        "visit_zip_code": "4921 BZ",
        "visit_city": "MADE",
        "invoice_street": " Middelmeede ",
        "invoice_street_nr": "40",
        "invoice_zip_code": "4921 BZ",
        "invoice_city": "MADE",
        "long": 4.79306,
        "lat": 51.67667
    },
    {
        "id": 62,
        "name": "Rochdale",
        "visit_street": " Bos en Lommerplein ",
        "visit_street_nr": "303",
        "visit_zip_code": "1055 RW",
        "visit_city": "Amsterdam",
        "invoice_street": " postbus ",
        "invoice_street_nr": "56659",
        "invoice_zip_code": "1040 AR",
        "invoice_city": "Amsterdam",
        "long": 4.8445303,
        "lat": 52.3775712
    },
    {
        "id": 63,
        "name": "Mainplus BV",
        "visit_street": " Laan van Vredenoord ",
        "visit_street_nr": "15",
        "visit_zip_code": "2289 DA",
        "visit_city": "RIJSWIJK",
        "invoice_street": " Laan van Vredenoord ",
        "invoice_street_nr": "15",
        "invoice_zip_code": "2289 DA",
        "invoice_city": "RIJSWIJK",
        "long": 4.34597,
        "lat": 52.0517312
    },
    {
        "id": 71,
        "name": "Hoogheemraadschap van Rijnland",
        "visit_street": " Archimedesweg ",
        "visit_street_nr": "1",
        "visit_zip_code": "2333 CM",
        "visit_city": "LEIDEN",
        "invoice_street": " Archimedesweg ",
        "invoice_street_nr": "1",
        "invoice_zip_code": "2333 CM",
        "invoice_city": "LEIDEN",
        "long": 4.4656609,
        "lat": 52.1646103
    },
    {
        "id": 72,
        "name": "Kennemer Wonen",
        "visit_street": " Hertog Aalbrechtweg ",
        "visit_street_nr": "18",
        "visit_zip_code": "1823 DL",
        "visit_city": "Alkmaar",
        "invoice_street": " Postbus ",
        "invoice_street_nr": "5010",
        "invoice_zip_code": "1802 TA",
        "invoice_city": "Alkmaar",
        "long": 4.7705979,
        "lat": 52.6457691
    },
    {
        "id": 73,
        "name": "Drechtsteden",
        "visit_street": " Industrieweg ",
        "visit_street_nr": "11",
        "visit_zip_code": "3361 HJ",
        "visit_city": "Sliedrecht",
        "invoice_street": " Industrieweg ",
        "invoice_street_nr": "11",
        "invoice_zip_code": "3361 HJ",
        "invoice_city": "Sliedrecht",
        "long": 4.768593,
        "lat": 51.8192116
    },
    {
        "id": 74,
        "name": "DeltaWonen",
        "visit_street": " Veerallee ",
        "visit_street_nr": "30",
        "visit_zip_code": "8019 AD",
        "visit_city": "Zwolle",
        "invoice_street": " Postbus ",
        "invoice_street_nr": "604",
        "invoice_zip_code": "8000 AP",
        "invoice_city": "Zwolle",
        "long": 6.0783663,
        "lat": 52.5071672
    },
    {
        "id": 75,
        "name": "Unified",
        "visit_street": " Otto Hahnweg ",
        "visit_street_nr": "52",
        "visit_zip_code": "3542 AX",
        "visit_city": "Utrecht",
        "invoice_street": " Otto Hahnweg ",
        "invoice_street_nr": "52",
        "invoice_zip_code": "3542 AX",
        "invoice_city": "Utrecht",
        "long": 5.0548207,
        "lat": 52.1167942
    },
    {
        "id": 76,
        "name": "WS Den Helder",
        "visit_street": " Middenweg ",
        "visit_street_nr": "159",
        "visit_zip_code": "1782 BE",
        "visit_city": "DEN HELDER",
        "invoice_street": " Helder Vastgoed B.V.",
        "invoice_street_nr": "0",
        "invoice_zip_code": "1780 AB",
        "invoice_city": "DEN HELDER",
        "long": 4.53333,
        "lat": 51.43333
    },
    {
        "id": 77,
        "name": "Wetland Wonen",
        "visit_street": " Weg van Rollecate",
        "visit_street_nr": "11",
        "visit_zip_code": "8325 CP",
        "visit_city": "Vollenhove",
        "invoice_street": " Weg van Rollecate",
        "invoice_street_nr": "11-A",
        "invoice_zip_code": "8325 CP",
        "invoice_city": "Vollenhove",
        "long": 5.9583639,
        "lat": 52.6811037
    },
    {
        "id": 78,
        "name": "Vecht en Omstreken",
        "visit_street": " Poeldijk ",
        "visit_street_nr": "2",
        "visit_zip_code": "3621 CZ",
        "visit_city": "Breukelen",
        "invoice_street": "",
        "invoice_street_nr": null,
        "invoice_zip_code": "",
        "invoice_city": "",
        "long": 4.9979689,
        "lat": 52.1687428
    },
    {
        "id": 79,
        "name": "Bouwkundig Adviesbureau Woningkeuze",
        "visit_street": " Rietvoorn ",
        "visit_street_nr": "2",
        "visit_zip_code": "3225 PH",
        "visit_city": "Hellevoetsluis",
        "invoice_street": " Rietvoorn ",
        "invoice_street_nr": "2",
        "invoice_zip_code": "3225 PH",
        "invoice_city": "Hellevoetsluis",
        "long": 4.1634027,
        "lat": 51.8324108
    },
    {
        "id": 80,
        "name": "New Capital Huang",
        "visit_street": " Grotesteenweg ",
        "visit_street_nr": "678",
        "visit_zip_code": "2600",
        "visit_city": "Belgie",
        "invoice_street": " Grotesteenweg ",
        "invoice_street_nr": "678",
        "invoice_zip_code": "",
        "invoice_city": "BERCHEM 2600 BELGIE",
        "long": 4.4366193,
        "lat": 51.1769818
    },
    {
        "id": 81,
        "name": "Kevin Rijsdijk",
        "visit_street": " Sportsingel ",
        "visit_street_nr": "24",
        "visit_zip_code": "3078 XS",
        "visit_city": "ROTTERDAM",
        "invoice_street": " Sportsingel ",
        "invoice_street_nr": "24",
        "invoice_zip_code": "3078 XS",
        "invoice_city": "ROTTERDAM",
        "long": 4.5344502,
        "lat": 51.8928797
    },
    {
        "id": 82,
        "name": "Studio Geer",
        "visit_street": " Mauritsweg ",
        "visit_street_nr": "75",
        "visit_zip_code": "2988 AG",
        "visit_city": "RIDDERKERK",
        "invoice_street": " Mauritsweg ",
        "invoice_street_nr": "75",
        "invoice_zip_code": "2988 AG",
        "invoice_city": "RIDDERKERK",
        "long": 4.60278,
        "lat": 51.8725
    },
    {
        "id": 91,
        "name": "Maasdelta",
        "visit_street": " Thorbeckelaan e",
        "visit_street_nr": "82",
        "visit_zip_code": "3201 WK",
        "visit_city": "Spijkenisse",
        "invoice_street": " postbus ",
        "invoice_street_nr": "34",
        "invoice_zip_code": "3200 AA",
        "invoice_city": "Spijkenisse",
        "long": 4.3338262,
        "lat": 51.8484672
    },
    {
        "id": 97,
        "name": "Auto- en Motorrijschool Ted",
        "visit_street": " Driedijk ",
        "visit_street_nr": "32",
        "visit_zip_code": "3079 PA",
        "visit_city": "Rotterdam",
        "invoice_street": " Driedijk ",
        "invoice_street_nr": "32",
        "invoice_zip_code": "3079 PA",
        "invoice_city": "Rotterdam",
        "long": 4.5431162,
        "lat": 51.8834245
    },
    {
        "id": 98,
        "name": "Mobility Totaal Service",
        "visit_street": " Wolweverstraat ",
        "visit_street_nr": "37",
        "visit_zip_code": "2984 CE",
        "visit_city": "Ridderkerk",
        "invoice_street": " Wolweverstraat ",
        "invoice_street_nr": "37",
        "invoice_zip_code": "2984 CE",
        "invoice_city": "Ridderkerk",
        "long": 4.6082505,
        "lat": 51.8779942
    },
    {
        "id": 99,
        "name": "De Goede Woning",
        "visit_street": " Maria Montessorilaan ",
        "visit_street_nr": "11",
        "visit_zip_code": "2719 DB",
        "visit_city": "Zoetermeer",
        "invoice_street": " Postbus ",
        "invoice_street_nr": "11",
        "invoice_zip_code": "2700 AA",
        "invoice_city": "Zoetermeer",
        "long": 4.4750259,
        "lat": 52.044863
    },
    {
        "id": 100,
        "name": "De Videomann",
        "visit_street": " Rijksstraatweg ",
        "visit_street_nr": "63",
        "visit_zip_code": "2988 BB",
        "visit_city": "Ridderkerk",
        "invoice_street": " Rijksstraatweg ",
        "invoice_street_nr": "63",
        "invoice_zip_code": "2988 BB",
        "invoice_city": "Ridderkerk",
        "long": 4.5971672,
        "lat": 51.8475115
    },
    {
        "id": 101,
        "name": "Relax and Refresh",
        "visit_street": " Vlambloem ",
        "visit_street_nr": "165",
        "visit_zip_code": "3068 JG",
        "visit_city": "Rotterdam",
        "invoice_street": " Vlambloem ",
        "invoice_street_nr": "165",
        "invoice_zip_code": "3068 JG",
        "invoice_city": "Rotterdam",
        "long": 4.5326277,
        "lat": 51.9543447
    },
    {
        "id": 102,
        "name": "Lageweg & Engel",
        "visit_street": " Albertplesmanplein ",
        "visit_street_nr": "1",
        "visit_zip_code": "3088 GS",
        "visit_city": "Rotterdam",
        "invoice_street": " Albert Plesmanplein ",
        "invoice_street_nr": "1",
        "invoice_zip_code": "3088 GS",
        "invoice_city": "ROTTERDAM",
        "long": 4.47917,
        "lat": 51.9225
    },
    {
        "id": 103,
        "name": "Sparkling Voices",
        "visit_street": " Albrandswaardsedijk ",
        "visit_street_nr": "52",
        "visit_zip_code": "3172 TJ",
        "visit_city": "Poortugaal",
        "invoice_street": " Albrandswaardsedijk ",
        "invoice_street_nr": "52",
        "invoice_zip_code": "3172 TJ",
        "invoice_city": "Poortugaal",
        "long": 4.3947505,
        "lat": 51.8523789
    },
    {
        "id": 104,
        "name": "Ateko BV",
        "visit_street": " Bazeldijk ",
        "visit_street_nr": "51",
        "visit_zip_code": "4231ZC",
        "visit_city": "Meerkerk",
        "invoice_street": " Bazeldijk ",
        "invoice_street_nr": "51",
        "invoice_zip_code": "4231ZC",
        "invoice_city": "Meerkerk",
        "long": 4.9965331,
        "lat": 51.9105705
    },
    {
        "id": 105,
        "name": "Welbions",
        "visit_street": " Paul Krugerstraat ",
        "visit_street_nr": "44",
        "visit_zip_code": "7551 GX",
        "visit_city": "Hengelo",
        "invoice_street": " Postbus ",
        "invoice_street_nr": "430",
        "invoice_zip_code": "7550 AK",
        "invoice_city": "Hengelo",
        "long": 6.7996001,
        "lat": 52.2669176
    },
    {
        "id": 106,
        "name": "Stichting Woonin",
        "visit_street": " Koningin Wilhelminalaan ",
        "visit_street_nr": "9",
        "visit_zip_code": "3527 LA",
        "visit_city": "UTRECHT",
        "invoice_street": " Koningin Wilhelminalaan ",
        "invoice_street_nr": "9",
        "invoice_zip_code": "3527 LA",
        "invoice_city": "UTRECHT",
        "long": 5.1055468,
        "lat": 52.0769193
    },
    {
        "id": 107,
        "name": "Lefier",
        "visit_street": "",
        "visit_street_nr": null,
        "visit_zip_code": "",
        "visit_city": "Stadskanaal",
        "invoice_street": " Postbus ",
        "invoice_street_nr": "2102",
        "invoice_zip_code": "7801 CC",
        "invoice_city": "Emmen",
        "long": 6.9504,
        "lat": 52.98947
    },
    {
        "id": 108,
        "name": "Nederlandse Fierljep Bond",
        "visit_street": "",
        "visit_street_nr": null,
        "visit_zip_code": null,
        "visit_city": "",
        "invoice_street": "",
        "invoice_street_nr": null,
        "invoice_zip_code": null,
        "invoice_city": "",
        "long": 7.5400398,
        "lat": 44.9379672
    }
];

const lateNight = (item) => {
  // Extract the start and end times from the item (assuming they are in a valid format)
  const startTime = new Date(item.Begonnen);
  const endTime = new Date(item.Geëindigd);

  // Define the time range for late-night trips (21:00 to 05:00)
  const lateNightStart = new Date(startTime);
  lateNightStart.setHours(21, 0, 0, 0); // Set to 21:00:00.000

  const lateNightEnd = new Date(startTime);
  lateNightEnd.setHours(5, 0, 0, 0); // Set to 05:00:00.000 of the next day

  // Check if either the start time or end time falls within the late-night range
  if (
    (startTime >= lateNightStart && startTime <= lateNightEnd) ||
    (endTime >= lateNightStart && endTime <= lateNightEnd)
  ) {
    return true; // It's a late-night trip
  }

  return false; // It's not a late-night trip
};

const weekendDays = (item) => {
  // Extract the date from the item (assuming it is in a valid format)
  const tripDate = new Date(item.Begonnen);

  // Check if the day of the week is Saturday (6) or Sunday (0)
  if (tripDate.getDay() === 6 || tripDate.getDay() === 0) {
    return true; // It's a weekend trip
  }

  return false; // It's not a weekend trip
};

const abroadTrip = async (startCoords) => {
  // Extract latitude and longitude from the item
  const lat = parseFloat(startCoords.lat); // Replace with the actual field name
  const lng = parseFloat(startCoords.lng); // Replace with the actual field name

  // Build the API request URL for reverse geocoding
  const apiUrl = `https://api.opencagedata.com/geocode/v1/json?q=${lat}+${lng}&key=${pinguin}`;

  try {
    // Send a request to the geocoding API
    const response = await fetch(apiUrl);
    const data = await response.json();

    if (data.results && data.results.length > 0) {
      const country = data.results[0].components.country;
      // Check if the country is not your own country
      const yourCountry = "Nederland"; // Replace with your country name
      if (country !== yourCountry) {
        return true; // It's an abroad trip
      }
    }

    return false; // It's not an abroad trip or couldn't determine the country
  } catch (error) {
    console.error("Error while fetching geocoding data:", error);
    return false; // Assume it's not an abroad trip in case of an error
  }
};

// Function to calculate the distance between two sets of coordinates using the Haversine formula
const calculateDistance = (lat1, lon1, lat2, lon2) => {
    const R = 6371; // Radius of the Earth in kilometers
    const d = Math.acos(
      Math.sin(lat1) * Math.sin(lat2) +
      Math.cos(lat1) * Math.cos(lat2) * Math.cos(lon2 - lon1)
    );
    const distance = R * d; // Distance in kilometers
    return distance;
  };
  

const clientCompanyAddress = (startCoords, endCoords) => {
  // Extract latitude and longitude from the trip
  const tripStartLat = parseFloat(startCoords.lat); // Replace with the actual field name
  const tripStartLon = parseFloat(startCoords.lng); // Replace with the actual field name
  const tripEndLat = parseFloat(endCoords.lat); // Replace with the actual field name
  const tripEndLon = parseFloat(endCoords.lng); // Replace with the actual field name

  // Define the maximum allowed distance in kilometers for a trip to be considered near a client address
  const maxDistance = 1; // Adjust as needed

  // Iterate over each client address
  for (const clientAddress of clientAddresses) {
    // Extract latitude and longitude from the client address
    const clientLat = parseFloat(clientAddress["lat"]); // Replace with the actual field name
    const clientLon = parseFloat(clientAddress["long"]); // Replace with the actual field name

    // Calculate the distance between the trip's start and the client address
    const startDistance = calculateDistance(
      tripStartLat,
      tripStartLon,
      clientLat,
      clientLon
    );

    // Calculate the distance between the trip's end and the client address
    const endDistance = calculateDistance(
      tripEndLat,
      tripEndLon,
      clientLat,
      clientLon
    );

    // Check if either the start or end of the trip is near the client address
    if (startDistance <= maxDistance || endDistance <= maxDistance) {
      return true; // The trip is near a client address
    }
  }

  return false; // The trip is not near any client address
};


// this function combines the above functions
const labeler = async (item, startCoords, endCoords) => {
    let points = 0; // Initialize points to 0
    const startPoints = 3; // Starting points value

    const isAbroad = await abroadTrip(startCoords);
    const isLateNight = lateNight(item)
    const isWeekendDays = weekendDays(item)
    const isClientCompanyNearby = await clientCompanyAddress(startCoords, endCoords)

    
    // Condition 1: If abroad, decrement by 2; if not abroad, increment by 1
    points += isAbroad ? -2 : 0;
    
    // Condition 2: If late night, decrement by 1; if not late night, increment by 1
    points += isLateNight ? -1 : 0;
    
    // Condition 3: If weekend days, decrement by 1; if not weekend days, increment by 1
    points += isWeekendDays ? -1 : 0;
    
    // Condition 4: If not abroad, check isClientCompanyNearby
    if (!isAbroad) {
      // If nearby, increment by 2; if not nearby, decrement by 1
      points += isClientCompanyNearby ? 2 : -1;
    }
    
    
    // Add startPoints to the calculated points
    points += startPoints;
    
    
    // Ensure points stay within the range of 1 to 5
    if (points > 5) {
      points = 5;
    } else if (points < 1) {
      points = 1;
    }
    
    
    

    return points;

}


export { labeler };
