import './App.css';
import { useState, useRef } from 'react';

import FileParser from './elements/FileParser';

import { generateExcel } from './elements/Document';

import SwipeableMaps from './elements/swipeComponent';

import { Download, RotateCcw } from 'react-feather';

function App() {

  const [hasFile, setHasFile] = useState(false)
  // const [startCoords, setStartCoords] = useState(null)
  // const [endCoords, setEndCoords] = useState(null)
  // const [hasCoords, setHasCoords] = useState(false)

  const [data, setData] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0)
  const [isPrevious, setIsPrevious] = useState(false)


  const swipeableMapsRef = useRef();

  const handleUndoParent = () => {
    if (swipeableMapsRef.current) {
      swipeableMapsRef.current.handleUndo();
    }
  };

  return (
    <div className="App">
      {!hasFile ? <FileParser setData={setData} setHasFile={setHasFile}/> : 
      <SwipeableMaps
        currentIndex={currentIndex} 
        ref={swipeableMapsRef} 
        setCurrentIndex={setCurrentIndex} 
        data={data} 
        setData={setData}
        isPrevious={isPrevious}
        setIsPrevious={setIsPrevious}
        /> }
      
      <button type="button" className="btn btn-download btn-right btn-primary" onClick={() => generateExcel(data)}><Download /></button>
      <button 
        type="button" 
        disabled={isPrevious || currentIndex===0} 
        className={`${isPrevious || currentIndex === 0 ? "btn-disabled" : ""} btn btn-undo btn-left btn-primary`} 
        onClick={handleUndoParent}>
          <RotateCcw />
      </button>

    </div>
  );
}

export default App;
