import React, { useState, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';

const FileUpload = ({ onFileUpload }) => {
  const [isDragActive, setIsDragActive] = useState(false);

  const onDragEnter = () => setIsDragActive(true);
  const onDragLeave = () => setIsDragActive(false);
  const onDrop = useCallback(acceptedFiles => {
    setIsDragActive(false);
    // Handle file upload
    onFileUpload(acceptedFiles[0]);
  }, [onFileUpload]);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    onDragEnter,
    onDragLeave
  });

  return (
    <div
      className={`upload-container ${isDragActive ? 'drag-over' : ''}`}
      {...getRootProps()}
    >
      <input {...getInputProps()} />
      <p>Drag 'n' drop a file here, or click to select a file</p>
    </div>
  );
};

export default FileUpload