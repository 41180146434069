import React, { useEffect, useRef } from "react";
import GoogleMapReact from "google-map-react";

import './index.css'

const MapComponent = ({ startCoords, endCoords, currentData, labelPoints }) => {
  const defaultCenter = { lat: 51.924419, lng: 4.477733 };
  const defaultZoom = 11;
  const mapRef = useRef();
  const mapsRef = useRef();
  const directionsDisplayRef = useRef(null);

  const handleApiLoaded = ({ map, maps }) => {
    mapRef.current = map;
    mapsRef.current = maps;
    updateRoute();
  };

  const updateRoute = () => {
    if (mapRef.current && mapsRef.current && startCoords && endCoords) {
      // If there's an existing directionsDisplay, remove it from the map
      if (directionsDisplayRef.current) {
        directionsDisplayRef.current.setMap(null);
      }
  
      // Create a new DirectionsService object
      const directionsService = new mapsRef.current.DirectionsService();
  
      // Create a new DirectionsRenderer object
      const directionsDisplay = new mapsRef.current.DirectionsRenderer();
      directionsDisplayRef.current = directionsDisplay; // Store the reference
      directionsDisplay.setMap(mapRef.current);
  
      // Request for the driving route
      const request = {
        origin: startCoords,
        destination: endCoords,
        travelMode: 'DRIVING',
      };
  
      // Fetch the route
      directionsService.route(request, (result, status) => {
        if (status === 'OK') {
          directionsDisplay.setDirections(result);
        }
      });
    }
  };
  

  useEffect(() => {
    updateRoute();
  }, [currentData, startCoords, endCoords]);

  return (
    <div className="map-container">
      <div className="map">
        <GoogleMapReact
          bootstrapURLKeys={{ key: "AIzaSyBuPEloMobRAiTwagctS2JTNUgpfDtEcNQ" }}
          defaultCenter={defaultCenter}
          defaultZoom={defaultZoom}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={handleApiLoaded}
        />
      </div>
      <div className="map-info">
        <div className="info-content">
          <div className="title">Trip Details<span className={`label-pointer pointer-color-${labelPoints}`}></span></div>
          <div className="details">
            <div>Date: {currentData.started_time}</div>
            <div>Distance: {currentData.distance}km</div>
            <div>Start: {currentData.start_point}</div>
            <div>End: {currentData.end_point}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MapComponent;
