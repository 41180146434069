import * as XLSX from 'xlsx';

const generateExcel = (unChangedData) => {

    const data = dataTransform(unChangedData)
    // Create a new workbook and a worksheet
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(data, {
        header: ['Datum', 'Beginlocatie + Begintijd', 'Bestemming + Eindtijd', 'Beschrijving/Notities', 'Beginstand kilometerteller', 'Eindstand kilometerteller', 'Aantal kilometers', 'Vergoeding'],
        skipHeader: false, // We will add this manually later to apply styles
    });

    // Define styles
    const headerStyle = {
        font: { bold: true },
        fill: { fgColor: { rgb: "CCCCCC" } },
        border: {
            top: { style: "thin", color: { auto: 1 } },
            right: { style: "thin", color: { auto: 1 } },
            bottom: { style: "thin", color: { auto: 1 } },
            left: { style: "thin", color: { auto: 1 } }
        }
    };

    // Apply styles to headers
    for (let i = 0; i < ws['!ref'].split(':')[1].charCodeAt(0) - 64; i++) {
        let cellRef = XLSX.utils.encode_cell({c: i, r: 0});
        if (!ws[cellRef]) ws[cellRef] = {};
        ws[cellRef].s = headerStyle;
    }

    // Add the header row (manually)
    const headerRow = ['Datum', 'Beginlocatie + Begintijd', 'Bestemming + Eindtijd', 'Beschrijving/Notities', 'Beginstand kilometerteller', 'Eindstand kilometerteller', 'Aantal kilometers', 'Vergoeding'];
    XLSX.utils.sheet_add_aoa(ws, [headerRow], {origin: 'A1'});

    // Add worksheet to workbook
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    // Generate Excel file and trigger download
    XLSX.writeFile(wb, 'Kilometerlogboek_en_onkostendeclaratie.xlsx');
};


const dataTransform = (data) => {
    const newData = data.filter(item => item.category === "business").map(item => ({
      "Datum": item.started_time.split(' ')[0], // Extract the date part
      "Beginlocatie + Begintijd": `${item.start_point}, (${item.started_time.split(' ')[1]})`, // Combine start location and time
      "Bestemming + Eindtijd": `${item.end_point}, (${item.ended_time.split(' ')[1]})`, // Combine end location and time
      "Beschrijving/Notities": item.notities || 'N/A', // Use notities or 'N/A' if not present
      "Beginstand kilometerteller": item.km_start,
      "Eindstand kilometerteller": item.km_end,
      "Aantal kilometers": item.distance.replace(',', '.'), // Ensure that the distance is in a proper decimal format
      "Vergoeding": (parseFloat(item.distance.replace(',', '.')) * 0.23).toFixed(2) // Calculate reimbursement based on distance and rate per km, for example €0.19
    }));
  
    return newData;
  }


export { generateExcel }