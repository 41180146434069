import React from 'react';
import FileUpload from '../FileUpload'; // Import the FileUpload component
import { readString } from 'react-papaparse';

const FileParser = ({setHasFile, setData}) => {

  // Translation object: Dutch to English
  const headerTranslation = {
    'categorie': 'category',
    'begonnen': 'started_time',
    'geëindigd': 'ended_time',
    'kilometerstand vertrekpunt (km)': 'starting_mileage_km',
    
    'kilometerstand_vertrekpunt_(km)': 'km_start',
    'kilometerstand_eindpunt_(km)': 'km_end',

    'afstand_(km)': 'distance',
    'startpunt': 'start_point',
    'eindpunt': 'end_point',
    'duur': 'duration'
  };

  // Function to translate header
  const translateHeader = header => headerTranslation[header] || header;

  const handleFileUpload = file => {
    const reader = new FileReader();
    reader.onload = (event) => {
      const csvData = readString(event.target.result, {
        header: true,
        delimiter: ';', // Use semi-colon as the delimiter
        skipEmptyLines: true,
        transformHeader: header => translateHeader(header.trim().toLowerCase().replace(/\s+/g, '_')),
      });
      setData(csvData.data);
      setHasFile(true)
    };
    reader.readAsText(file);
  };

  return (
    <div>
      <FileUpload onFileUpload={handleFileUpload} />
      {/* Render your data here */}
    </div>
  );
};

export default FileParser;
